
import { defineComponent } from 'vue';
import { useQuery, useMutation } from 'villus';
import { useRouter } from 'vue-router';
import UserForm from '../components/UserForm.vue';

export default defineComponent({
  components: { UserForm },

  props: {
    id: String,
  },

  setup(props) {
    const router = useRouter();

    const User = `
      query {
        user(id: ${props.id}) {
          id
          email
          firstName
          lastName
          zipcode
          streetWithHouseNumber
          gender
          town
          province
          country
          phonenumber
          birthdate
          mobilePhonenumber
          iban
          bic
          unregisterDate
          memberships {
            id
            role
            groupId
          }
        }
      }
    `;

    const { data: userResult } = useQuery(User);

    const UpdateUser = `
      mutation UpdateUser($id: ID!,
                          $firstName: String,
                          $lastName: String!,
                          $email: String,
                          $phonenumber: String,
                          $mobilePhonenumber: String,
                          $gender: String!,
                          $streetWithHouseNumber: String!,
                          $zipcode: String!,
                          $town: String!,
                          $province: String!,
                          $iban: String,
                          $bic: String,
                          $birthdate: String,
                          $unregisterDate: String,
                          $memberships: [MembershipInput]) {
        UpdateUser(id: $id,
                   firstName: $firstName,
                   lastName: $lastName,
                   email: $email,
                   phonenumber: $phonenumber,
                   mobilePhonenumber: $mobilePhonenumber,
                   streetWithHouseNumber: $streetWithHouseNumber,
                   gender: $gender,
                   zipcode: $zipcode,
                   town: $town,
                   province: $province,
                   iban: $iban,
                   bic: $bic,
                   birthdate: $birthdate,
                   unregisterDate: $unregisterDate,
                   memberships: $memberships)  {
          id
        }
      }
    `;

    const { execute } = useMutation(UpdateUser);

    const saveUser = (user: User) => {
      execute(user)
        .then((result) => {
          router.replace({ name: 'UserDetails', params: { id: result.data.UpdateUser.id } });
        });
    };

    return { userResult, saveUser };
  },
});
